import { CountryCode } from 'libphonenumber-js';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import './input.css';
import { Field, Label } from '../ui/fieldset';
import { CountrySelect } from './country-select';
import { Button } from '../ui/button';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
interface PhoneNumberFormProps {
  onSubmit: (phone: string) => void;
}

const defaultCountryCode = 'SE';

export const PhoneNumberForm = ({ onSubmit }: PhoneNumberFormProps) => {
  const [value, setValue] = useState<string>();
  const [isValid, setIsValid] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleChange = (value?: string) => {
    if (value) {
      const parsedNumber = parsePhoneNumber(value);
      setIsValid(parsedNumber?.isValid() || false);
    }
    setValue(value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (value && isValid) {
      onSubmit(value);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col">
      <Field className="flex flex-col">
        <Label className="mb-2">{t('completeprofile.phone.header')}</Label>
        <PhoneInput
          numberInputProps={{
            style: {
              position: 'relative',
              marginLeft: '10px',
              display: 'block',
              height: '44px',
              width: '100%',
              appearance: 'none',
              borderRadius: '0.5rem',
              paddingLeft: 'calc(0.875rem - 1px)',
              paddingRight: 'calc(0.875rem - 1px)',
              paddingTop: 'calc(0.625rem - 1px)',
              paddingBottom: 'calc(0.625rem - 1px)',
              fontSize: '1rem',
              lineHeight: '1.5',
              color: '#fff',
              '::placeholder': {
                color: '#71717a',
              },
              border: '1px solid rgb(88 105 126)',
              backgroundColor: 'rgb(38, 42, 51)',
              outline: 'none',

              ':hover': {
                border: '1px solid rgba(24, 24, 27, 0.2)',
              },
              ':invalid': {
                border: '1px solid #ef4444',
                ':hover': {
                  border: '1px solid #ef4444',
                },
              },
              ':disabled': {
                border: '1px solid rgba(24, 24, 27, 0.2)',
                backgroundColor: 'rgba(255, 255, 255, 0.025)',
              },
            },
          }}
          style={{ backgroundColor: 'transparent' }}
          defaultCountry={defaultCountryCode as CountryCode}
          international
          class={`PhoneInputInput ${isValid ? '' : 'error'}`}
          onChange={handleChange}
          countrySelectComponent={CountrySelect}
          value={value}
          autoFocus
        />

        <Button type="submit" color="blue" className="w-full self-end mt-8">
          {t('phone.continue')}
          <ArrowRightIcon />
        </Button>
      </Field>
    </form>
  );
};
