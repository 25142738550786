import { useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { ChevronUpIcon } from '@heroicons/react/24/outline';

export function ScrollButton() {
  const [isVisible, setIsVisible] = useState(false);

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -250) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <button
      onClick={scrollToTop}
      className={`fixed right-8 bottom-8 p-4 bg-blue-600 text-white rounded-full transition-transform duration-300 ${
        isVisible ? 'translate-y-0' : 'translate-y-20'
      }`}
      aria-label="scroll-to-top"
      style={{ transition: 'all .3s ease' }}
    >
      <ChevronUpIcon className="h-6 w-6" />
    </button>
  );
}
