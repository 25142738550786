import * as i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'general.hello': 'Hello',
      'general.tickets': 'Tickets',
      'general.date': 'Date',
      'general.price': 'Price',
      'general.facility': 'Facility',
      'general.checked-in': 'Checked in',
      'general.paid': 'Paid',
      'login.header': 'Welcome, log in to continue',
      'login.button.BankID': 'Log in with BankID',
      'login.consent.1': 'By clicking on ',
      'login.consent.2': 'Terms',
      'login.consent.3': ' and ',
      'login.consent.4': 'Privacy policy',
      'login.consent.5':
        'and consent to the processing of your personal data in accordance with our ',
      'login.checkbox.consent': 'I agree to the privacy policy',
      'login.BankID': 'Cannot log in with swedish BankID?',
      'login.button.phone': 'Log in with phone number',
      'appbar.guest': 'Guest',
      'appbar.code': 'My code',
      'appbar.ticket': 'My ticket',
      'appbar.receipts': 'My receipts',
      'appbar.business': 'Business',
      'appbar.app': 'Download the App',
      'appbar.profile': 'My profile',
      'appbar.logout': 'Log out',
      'phone.header': 'Log in',
      'phone.country': 'Country',
      'phone.phone': 'Phone number',
      'phone.phone.error1': 'Number not valid',
      'phone.phone.error2': 'Enter your number',
      'phone.phone.helper': 'Expected format: 701234567',
      'phone.consent.1': 'I have read and agree to the ',
      'phone.consent.2': 'Terms',
      'phone.consent.3': ' and ',
      'phone.consent.4': 'Privacy policy',
      'phone.consent.error': 'Read and agree to the terms and policy',
      'phone.continue': 'Continue',
      'verification.header': 'Enter verification code',
      'verification.subheader': 'The code has been sent to your number',
      'verification.label': 'Verification code',
      'verification.error': 'Enter verification code',
      'verification.body1': `Was the code not delivered?`,
      'verification.body2': 'Send again',
      'verification.button': 'Confirm',
      'verification.error1': 'Code not valid',
      'verification.error2': 'Internal error',
      'completeprofile.header': 'Make your tickets findable by entering date of birth',
      'completeprofile.firstName': 'First name',
      'completeprofile.lastName': 'Last name',
      'completeprofile.name.error': 'Enter name',
      'completeprofile.button.continue': 'Continue',
      'completeprofile.dob': 'Date of birth',
      'completeprofile.year': 'Year',
      'completeprofile.month': 'Month',
      'completeprofile.day': 'Day',
      'completeprofile.date.error1': 'Missing',
      'completeprofile.date.error2': 'Not valid',
      'completeprofile.male': 'Male',
      'completeprofile.female': 'Female',
      'completeprofile.other': 'Other',
      'completeprofile.gender.error': 'Enter gender',
      'completeprofile.button.save': 'Save',
      'completeprofile.phone.header': 'Enter phone number',
      'completeprofile.gender': 'Gender',
      'completeprofile.link.privacy': 'Read our privacy policy',
      'completeprofile.subheader':
        "It's important that you enter your date of birth correctly. It will help us find you in our system if you lose your phone or it runs out of battery.",
      'dashboard.header': 'Show your code in the coat check',
      'dashboard.purchase': 'Purchase ticket',
      'dashboard.enter_payment_code': 'Enter payment code',
      'dashboard.payment_code': 'Payment code',
      'dashboard.continue': 'Continue',
      'dashboard.pay': 'Pay',
      'dashboard.show-qr': 'Show QR code in wardrobe',
      'dashboard.paid-at': 'Paid at',
      'dashboard.checked-in-at': 'Checked in at',
      'ticket.header': 'Coat check ticket',
      'ticket.battery1': 'Low battery?',
      'ticket.battery2': `No worries. Show your ID during checkout`,
      'profile.terms': 'Terms',
      'profile.policy': 'Privacy policy',
      'profile.support':
        'In case of any problems or questions, please send an email to support@easyjacket.se',
      'profile.logout': 'Log out',
      'profile.phone.header': 'Change your phone number',
      'profile.communication': 'Communication',
      'about.login1': 'What is EasyJacket?',
      'about.login2':
        'EasyJacket is a digital solution that allows you to quickly and conveniently store coat check tickets directly in your smartphone.',
      'about.login3': 'How does it work?',
      'about.login4': 'Log in with BankID.',
      'about.login5': 'Show your personal QR code to the staff in the coat check.',
      'about.login6':
        'We send a text message with a link to your digital ticket. You show the ticket when you want to pick up your jacket.',
      'about.login7': 'Frequently asked questions',
      'about.login8': 'What happens if my battery runs out or I lose my device?',
      'about.login9':
        'As your ticket is linked to your account, you only need to present valid identification to the coat check staff.',
      'about.login10': 'Why do I not get a physical coat check ticket?',
      'about.login11': `EasyJacket's coat check tickets are completely digital and are instead securely linked to your account.`,
      'about.checkedin1': 'How do I check out my jacket?',
      'about.checkedin2.1': 'Have coat check staff scan your ticket above. ',
      'about.checkedin2.2': 'A text message with a link to this page ',
      'about.checkedin2.3': 'has also been sent to your phone number.',
      'about.checkedin3': 'Frequently asked questions',
      'about.checkedin4': 'Do I have to save this page?',
      'about.checkedin5':
        'No. You can easily come back here by opening the link in the text message we have sent you.',
      'about.checkedin6': 'What happens if my battery runs out or I lose my device?',
      'about.checkedin7':
        'As your ticket is linked to your account, you only need to present valid identification to the coat check staff.',
      'about.checkedin8': 'I have not received a text message, what do I do?',
      'about.checkedin9':
        'It may take up to 30 seconds before you receive your text message. If you still have not received a text message, you can go to easyjacket.se to find your ticket.',
      'about.checkedout1': 'How do I check in my jacket?',
      'about.checkedout2':
        'Have the coat check staff scan your personal QR code and hand over your jacket and any accessories.',
      'about.checkedout3': 'Frequently asked questions',
      'about.checkedout4': 'Where is my coat check ticket?',
      'about.checkedout5':
        'Once the staff has scanned your code, the ticket will be visible at the top of this page. A text message with a link to your ticket will also be sent to your phone number.',
      'about.checkedout6': 'What happens if my battery runs out or I lose my device?',
      'about.checkedout7':
        'As your ticket is linked to your account, you only need to present valid identification to the coat check staff.',
      'about.checkedout8': 'I entered the wrong phone number, what do I do now?',
      'about.checkedout9.1':
        'No problem! If you have logged in with BankID, you can easily change your phone number by navigating to your ',
      'about.checkedout9.2': 'profile.',
      'receipts.header': 'My Receipts',
      'receipt.header': 'Receipt',
      'receipt.download': 'Download receipt',
      'receipt.refund': 'Refund',
      'receipt.facility': 'Facility',
      'receipt.refundRequested': 'Refund requested',

      // Refunds
      'refunds.header': 'Request refund',
      'refunds.message': 'Do you want to request a refund for this ticket?\nExplain why below.',
      'refunds.cancel': 'Cancel',
      'refunds.submit': 'Request refund',

      'tickets.checked-in-at': 'Checked in at',
      'tickets.buy-ticket': 'Buy ticket',
      'tickets.paid-at': 'Paid at',
      'tickets.consumed-at': 'Used at',
      'tickets.show-qr': 'Show QR code in wardrobe',
      'tickets.show-qr-entrance': 'Show QR code at entrance',

      // Pricing and payment
      'pricing.amount': 'Amount',
      'pricing.total': 'Total',
      'pricing.vat': 'VAT',
    },
  },
  sv: {
    translation: {
      'general.hello': 'Hej',
      'general.tickets': 'Biljetter',
      'general.checked-in': 'Incheckat',
      'general.date': 'Datum',
      'general.price': 'Pris',
      'general.facility': 'Facilitet',
      'general.paid': 'Betalda',
      'login.header': 'Välkommen, logga in för att fortsätta',
      'login.button.BankID': 'Logga in med BankID',
      'login.consent.1': 'Genom att klicka på ',
      'login.consent.2': 'Användarvillkor',
      'login.consent.3': ' och ',
      'login.consent.4': 'Integritetspolicy',
      'login.consent.5': 'och samtycker till behandlingen av dina personuppgifter enligt vår',
      'login.checkbox.consent': 'Jag godkänner integritetspolicyn',
      'login.BankID': 'Kan du inte logga in med BankID?',
      'login.button.phone': 'Logga in med telefonnummer',
      'appbar.guest': 'Gäst',
      'appbar.code': 'Min kod',
      'appbar.ticket': 'Min biljett',
      'appbar.receipts': 'Mina kvitton',
      'appbar.business': 'Företag',
      'appbar.app': 'Ladda ner Appen',
      'appbar.profile': 'Min profil',
      'appbar.logout': 'Logga ut',
      'phone.header': 'Logga in',
      'phone.country': 'Land',
      'phone.phone': 'Telefonnummer',
      'phone.phone.error1': 'Nummer ej giltigt',
      'phone.phone.helper': 'Förväntat format: +46701234567',
      'phone.phone.error2': 'Ange ditt telefonnummer',
      'phone.consent.1': 'Jag har läst och accepterar EasyJackets ',
      'phone.consent.2': 'Användarvillkor',
      'phone.consent.3': ' och ',
      'phone.consent.4': 'Integritetspolicy',
      'phone.consent.error': 'Läs och acceptera villkoren och policyn',
      'phone.continue': 'Fortsätt',
      'verification.header': 'Ange verifieringskoden',
      'verification.subheader': 'Koden har skickats till dig via SMS',
      'verification.label': 'Verifieringskod',
      'verification.error': 'Ange verifieringskoden',
      'verification.body1': 'Fick du ingen kod? ',
      'verification.body2': 'Skicka igen',
      'verification.button': 'Bekräfta',
      'verification.error1': 'Kod ej giltig',
      'verification.error2': 'Internt fel',
      'completeprofile.header': 'Gör dina biljetter sökbara genom att ange födelsedatum',
      'completeprofile.firstName': 'Förnamn',
      'completeprofile.lastName': 'Efternamn',
      'completeprofile.name.error': 'Ange namn',
      'completeprofile.button.continue': 'Fortsätt',
      'completeprofile.dob': 'Födelsedatum',
      'completeprofile.year': 'År',
      'completeprofile.month': 'Månad',
      'completeprofile.day': 'Dag',
      'completeprofile.date.error1': 'Saknas',
      'completeprofile.date.error2': 'Ej giltigt',
      'completeprofile.male': 'Man',
      'completeprofile.female': 'Kvinna',
      'completeprofile.other': 'Övrigt',
      'completeprofile.gender.error': 'Ange kön',
      'completeprofile.button.save': 'Spara',
      'completeprofile.phone.header': 'Ange telefonnummer',
      'completeprofile.gender': 'Kön',
      'completeprofile.link.privacy': 'Läs vår integritetspolicy',
      'completeprofile.subheader':
        'Det är viktigt att du anger ditt födelsedatum korrekt. Det hjälper oss att hitta dig i vårt system om du tappar bort din telefon eller om den tar slut på batteri.',
      'dashboard.header': 'Visa upp din kod i garderoben',
      'dashboard.purchase': 'Köp ny biljett',
      'dashboard.enter_payment_code': 'Ange betalkod',
      'dashboard.payment_code': 'Ange betalkod',
      'dashboard.continue': 'Fortsätt',
      'dashboard.show-qr': 'Visa upp QR-kod i garderoben',
      'dashboard.pay': 'Betala',
      'dashboard.paid-at': 'Betald hos',
      'dashboard.checked-in-at': 'Incheckad hos',
      'ticket.header': 'Biljett',
      'ticket.battery1': 'Lågt batteri?',
      'ticket.battery2': 'Inga problem. Visa upp din legitimation vid utcheckning',
      'profile.terms': 'Användarvillkor',
      'profile.policy': 'Integritetspolicy',
      'profile.support': 'Vid eventuella problem eller frågor, mejla support@easyjacket.se',
      'profile.logout': 'Logga ut',
      'profile.phone.header': 'Ändra ditt telefonnummer',
      'profile.communication': 'Kommunikation',
      'about.login1': 'Vad är EasyJacket?',
      'about.login2':
        'EasyJacket är en digital lösning som tillåter dig att snabbt och smidigt lagra biljetter direkt i mobilen.',
      'about.login3': 'Hur fungerar det?',
      'about.login4': 'Logga in med BankID.',
      'about.login5': 'Visa upp din personliga QR-kod till personalen i garderoben.',
      'about.login6':
        'Vi skickar ett SMS med länk till din digitala biljett. Biljetten visar du upp när du vill hämta ut din jacka.',
      'about.login7': 'Vanliga frågor',
      'about.login8': 'Vad händer om mitt batteri tar slut eller om jag tappar bort min mobil?',
      'about.login9':
        'Eftersom din biljett är kopplad till ditt konto behöver du endast visa upp giltig legitimation eller ange ditt telefonnummer du använt för att registrera ett konto för att hämta ut din jacka.',
      'about.login10': 'Varför får jag ingen fysisk biljett?',
      'about.login11':
        'Den fysiska biljetten är ett minne blott! EasyJackets biljetter är helt digitala och kopplas istället säkert till ditt konto.',
      'about.checkedin1': 'Hur hämtar jag ut min jacka?',
      'about.checkedin2.1': 'Låt garderobspersonal skanna av din biljett ovanför. ',
      'about.checkedin2.2': 'Ett SMS med länk till denna sida ',
      'about.checkedin2.3': 'har även skickats till ditt telefonnummer.',
      'about.checkedin3': 'Vanliga frågor',
      'about.checkedin4': 'Måste jag spara denna sida?',
      'about.checkedin5':
        'Nej. Du kan enkelt komma tillbaka hit genom att öppna länken i det SMS vi har skickat till dig.',
      'about.checkedin6': 'Vad händer om mitt batteri tar slut eller om jag tappar bort min mobil?',
      'about.checkedin7':
        'Eftersom din biljett är kopplad till ditt konto behöver du endast visa upp giltig legitimation eller ange ditt telefonnummer du använt för att registrera ett konto för att hämta ut din jacka.',
      'about.checkedin8': 'Jag har inte fått något SMS, hur gör jag?',
      'about.checkedin9':
        'Det kan ta upp till 30 sekunder innan du får ditt SMS. Om du fortfarande inte har fått något SMS kan du gå in på easyjacket.se för att hitta din biljett.',
      'about.checkedout1': 'Hur hänger jag in min jacka?',
      'about.checkedout2':
        'Låt garderobspersonal skanna av din personliga QR-kod och överlämna jacka och eventuella tillbehör.',
      'about.checkedout3': 'Vanliga frågor',
      'about.checkedout4': 'Vart är min biljett?',
      'about.checkedout5':
        'När personalen har skannat av din kod kommer biljetten synas högst upp på denna sida.',
      'about.checkedout6':
        'Vad händer om mitt batteri tar slut eller om jag tappar bort min mobil?',
      'about.checkedout7':
        'Eftersom din biljett är kopplad till ditt konto behöver du endast visa upp giltig legitimation eller ange ditt telefonnummer du använt för att registrera ett konto för att hämta ut din jacka.',
      'about.checkedout8': 'Jag skrev in fel telefonnummer, vad gör jag nu?',
      'about.checkedout9.1':
        'Inga problem! Om du har loggat in med mobilt BankID kan du enkelt ändra ditt telefonnummer genom att navigera till din ',
      'about.checkedout9.2': 'profil.',
      'receipts.header': 'Mina Kvitton',
      'receipt.header': 'Kvitto',
      'receipt.download': 'Ladda ner kvitto',
      'receipt.refund': 'Återbetala',
      'receipt.facility': 'Facilitet',
      'receipt.refundRequested': 'Återbetalning begärd',

      // Refunds
      'refunds.header': 'Begär återbetalning',
      'refunds.message': 'Vill du begära en återbetalning? Förklara kort varför nedan.',
      'refunds.cancel': 'Avbryt',
      'refunds.submit': 'Skicka',

      'tickets.checked-in-at': 'Incheckad hos',
      'tickets.buy-ticket': 'Köp biljett',
      'tickets.paid-at': 'Betald hos',
      'tickets.consumed-at': 'Använd hos',
      'tickets.show-qr': 'Visa QR-kod i garderoben',
      'tickets.show-qr-entrance': 'Visa QR-kod i entrén',

      // Pricing and payment
      'pricing.amount': 'Belopp',
      'pricing.total': 'Totalt',
      'pricing.vat': 'moms',
    },
  },
};

const tryGetLangauge = () => {
  try {
    const lang = navigator.language.split('-')[0];
    if (lang !== 'sv') {
      return 'en';
    }
    return 'sv';
  } catch (e) {
    return 'sv';
  }
};

export const i18n = i18next.use(initReactI18next).init({
  resources,
  lng: tryGetLangauge(),
  interpolation: {
    escapeValue: false,
  },
});
