import { SessionResponseError } from '../generated';
import { Spinner } from './spinner';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Heading } from './ui/heading';
import { Divider } from './ui/divider';
import { Text } from './ui/text';
import { Button } from './ui/button';

interface Props {
  data?: SessionResponseError;
  onClose: () => void;
  loading: boolean;
}

export const BankIdBanner = ({ data, onClose, loading }: Props) => {
  const getMessage = () => {
    if (!data) return '';
    if (data.grandidObject?.message?.status === 'pending') {
      return 'Starta BankID-appen.';
    }
    if (data.grandidObject?.message?.status === 'failed') {
      return 'Åtgärden avbruten.';
    }
    return JSON.stringify(data, null, 2);
  };

  if (!data && !loading) return null;

  return (
    <div className="w-full max-w-xs mx-auto p-4">
      <div>
        <Heading>Logga in med mobilt BankID</Heading>
      </div>
      <Divider className="mt-4" />
      <div className="flex items-center justify-center space-x-4 pt-4">
        <div>
          {loading && !data ? (
            <Spinner />
          ) : (
            <ExclamationTriangleIcon className="text-red-500" style={{ fontSize: '32px' }} />
          )}
        </div>
        <Text className="text-sm text-gray-100">{getMessage()}</Text>
      </div>

      <Button color="amber" className="mt-8 w-full" onClick={onClose}>
        Avbryt
      </Button>
    </div>
  );
};
