import { useNavigate, useSearchParams } from 'react-router-dom';
import { useWindowSize } from '../hooks/use-window-size';
import { useEffect, useState } from 'react';
import { ACCESS_TOKEN_KEY, api, createAuthHeader } from '../api';
import { useQueryClient } from '@tanstack/react-query';
import { useGetCurrentUser } from '../api/queries';

export const SmsLinkView = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { height } = useWindowSize();
  const [params] = useSearchParams();
  const [message, setMessage] = useState('');
  const queryClient = useQueryClient();
  const { isError } = useGetCurrentUser();

  const token = params.get('token');

  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        setMessage('Invalid token');
        setLoading(false);
        return;
      }

      try {
        localStorage.setItem(ACCESS_TOKEN_KEY, token);
        const { data } = await api.usersControllerFindMe({
          headers: {
            Authorization: createAuthHeader(token),
          },
        });
        if (data?.accessToken) {
          await queryClient.invalidateQueries(['currentUser']);
          setTimeout(() => {
            navigate('/dashboard');
          }, 2000);
        } else {
          throw new Error('Invalid token');
        }
      } catch (error) {
        console.error(error);
        setMessage('Link has expired or is invalid...');
        setLoading(false);
      }
    };

    verifyToken();
  }, [token, navigate, queryClient]);

  return (
    <div className="flex flex-col justify-start bg-black" style={{ height: `${height}px` }}>
      {loading || !isError ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black text-white z-50">
          <div className="flex flex-col items-center justify-center gap-5">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-white"></div>
            <h1 className="text-4xl">{message || 'Loading...'}</h1>
          </div>
        </div>
      ) : (
        <div className="container mx-auto max-w-xs mt-10 text-center">
          <h1 className="text-4xl text-white mb-4">
            {message || 'Something went wrong. Please try again.'}
          </h1>
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded w-full"
            onClick={() => (window.location.href = '/')}
          >
            Go to Home
          </button>
        </div>
      )}
    </div>
  );
};
