import { OTPInput, SlotProps } from 'input-otp';
import { cn } from '../../utils/css';

interface OTPFormProps {
  onComplete?: (arg: string) => void;
}

export const OTPForm = ({ onComplete }: OTPFormProps) => {
  const handleComplete = (val: string) => {
    onComplete && onComplete(val);
  };

  function Slot(props: SlotProps) {
    return (
      <div
        className={cn(
          'relative w-10 h-14 text-xl text-textPrimary bg-bgSecondary/50',
          'flex items-center justify-center',
          'transition-all duration-150',
          'border-borderPrimary border-y border-r first:border-l first:rounded-l-md last:rounded-r-md',
          'group-hover:border-borderPrimary group-focus-within:border-borderPrimary',
          'outline outline-0 outline-borderSecondary',
          { 'outline-1 outline-primaryLighter rounded-sm bg-bgSecondary': props.isActive },
        )}
      >
        {props.char !== null && <div>{props.char}</div>}
        {props.hasFakeCaret && <FakeCaret />}
      </div>
    );
  }
  return (
    <form className="mx-auto py-8">
      <OTPInput
        autoFocus
        inputMode="numeric"
        onComplete={(val) => handleComplete(val)}
        maxLength={6}
        pushPasswordManagerStrategy="none"
        containerClassName="group flex items-center has-[:disabled]:opacity-30"
        render={({ slots }) => (
          <>
            <div className="flex">
              {slots.slice(0, 3).map((slot, idx) => (
                <Slot key={idx} {...slot} />
              ))}
            </div>

            <FakeDash />

            <div className="flex">
              {slots.slice(3).map((slot, idx) => (
                <Slot key={idx} {...slot} />
              ))}
            </div>
          </>
        )}
      />
    </form>
  );
};

function FakeCaret() {
  return (
    <div className="absolute pointer-events-none inset-0 flex items-center justify-center animate-caret-blink">
      <div className="w-px h-5 bg-textPrimary" />
    </div>
  );
}

function FakeDash() {
  return (
    <div className="flex w-10 justify-center items-center">
      <div className="w-4 h-1 rounded-full bg-borderPrimary" />
    </div>
  );
}
