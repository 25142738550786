import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { api } from '../api';
import { Spinner } from '../components/spinner';
import { RouteNavigation } from '../components/ui/navigations';
import { InboxArrowDownIcon } from '@heroicons/react/24/outline';
import { Subheading } from '../components/ui/heading';
import { Button } from '../components/ui/button';
import { Divider } from '../components/ui/divider';
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
} from '../components/ui/description-list';
import { format } from 'date-fns';
import { CreateRefundRequestDto } from '../generated';
import { FormEvent, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '../components/ui/dialog';
import { Field } from '../components/ui/fieldset';
import { Textarea } from '../components/ui/textarea';

export const ReceiptView = () => {
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState('');

  const { t } = useTranslation();
  const { id } = useParams();

  const { data: receipt } = useQuery({
    queryKey: ['receipt', id],
    queryFn: async () => {
      const { data } = await api.paymentsControllerFindOneForUser({ paymentId: id as string });
      return data;
    },
  });

  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: async (data: CreateRefundRequestDto) =>
      api.refundRequestsControllerCreate({
        createRefundRequestDto: data,
      }),
    onSuccess: () => {
      setOpen(false);
      queryClient.invalidateQueries(['receipt', id]);
    },
  });

  const hasRefundRequest = receipt && receipt?.refundRequests.length > 0;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (hasRefundRequest) return;

    mutate({
      paymentId: id as string,
      reason,
    });
  };

  const formattedDate = receipt && format(new Date(receipt.createdAt), 'HH:mm – EE  M MMMM, yyyy');

  if (!receipt)
    return (
      <div className="w-full min-h-dvh grid place-content-center">
        <Spinner />
      </div>
    );

  const priceData = {
    amount: ((receipt.amount * 0.8) / 100).toLocaleString('sv-SE') + ' ' + receipt.currency,
    vat: ((receipt.amount * 0.2) / 100).toLocaleString('sv-SE') + ' ' + receipt.currency,
    total: (receipt.amount / 100).toLocaleString('sv-SE') + ' ' + receipt.currency,
  };

  const DownloadButton = () => (
    <button
      onClick={() => {
        window.open(receipt.receiptUrl, '_blank');
      }}
      className="size-10 bg-white/5 flex items-center justify-center rounded-full"
    >
      {<InboxArrowDownIcon className="!text-primary size-6" />}
    </button>
  );

  return (
    <>
      <section className="size-full overflow-y-scroll relative">
        <RouteNavigation sticky label={''} route="/receipts" actionComponent={<DownloadButton />} />
        <div className="bg-bgSecondary rounded-xl py-4 flex items-center justify-center flex-col gap-4 m-4">
          <Subheading>{t('receipt.header')}</Subheading>
          <DescriptionList className="w-full px-4">
            <DescriptionTerm>{t('pricing.amount')}</DescriptionTerm>
            <DescriptionDetails>{priceData.amount}</DescriptionDetails>

            <DescriptionTerm>{t('pricing.vat')}</DescriptionTerm>
            <DescriptionDetails>{priceData.vat}</DescriptionDetails>

            <DescriptionTerm>{t('pricing.total')}</DescriptionTerm>
            <DescriptionDetails>{priceData.total}</DescriptionDetails>
          </DescriptionList>
          <Divider />

          <DescriptionList className="w-full px-4">
            <DescriptionTerm>{t('general.facility')}</DescriptionTerm>
            <DescriptionDetails>{receipt.facility.name}</DescriptionDetails>

            <DescriptionTerm>{t('general.date')}</DescriptionTerm>
            <DescriptionDetails>{formattedDate}</DescriptionDetails>

            <DescriptionTerm>Payment method</DescriptionTerm>
            <DescriptionDetails>{receipt.paymentMethod}</DescriptionDetails>

            <DescriptionTerm>Referens</DescriptionTerm>
            <DescriptionDetails>{receipt.id}</DescriptionDetails>

            <DescriptionTerm>Status</DescriptionTerm>
            <DescriptionDetails>{receipt.status}</DescriptionDetails>
          </DescriptionList>
        </div>
        <div className="bg-bgSecondary rounded-xl p-4 flex flex-col items-center justify-center gap-8 m-4">
          <Button
            color="blue"
            className="w-full"
            onClick={() => setOpen(true)}
            disabled={hasRefundRequest}
          >
            {hasRefundRequest ? t('receipt.refundRequested') : t('refunds.header')}
          </Button>
        </div>
      </section>
      <Dialog open={open} onClose={setOpen}>
        <DialogTitle>{t('refunds.header')}</DialogTitle>
        <DialogDescription>{t('refunds.message')}</DialogDescription>
        <form onSubmit={handleSubmit}>
          <DialogBody>
            <Field>
              <Textarea
                required
                rows={4}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Field>
          </DialogBody>
          <DialogActions>
            <Button plain onClick={() => setOpen(false)}>
              {t('refunds.cancel')}
            </Button>
            <Button color="blue" type="submit">
              {t('refunds.submit')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
