import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';
import { useUpdateCurrentUser } from '../api/mutations';
import {
  ArrowRightEndOnRectangleIcon,
  ArrowUturnLeftIcon,
  CakeIcon,
  ChevronRightIcon,
  DocumentTextIcon,
  PhoneIcon,
  SpeakerWaveIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../components/ui/button';

export const ProfileView = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateCurrentUser } = useUpdateCurrentUser();
  const { logout, user: currentUser } = useAuth();
  const date = new Date(currentUser?.user?.birthdate ?? '');
  const formatedDate = date.toLocaleDateString('sv-SE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const handleUpdateCommunication = (allowCommunication: boolean) => {
    updateCurrentUser({ allowCommunication });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleOptOut = () => {
    handleUpdateCommunication(false);
    setDialogOpen(false);
  };

  return (
    <>
      <div>
        <div className="container mx-auto">
          <div className="flex justify-between mb-4 text-white mt-4">
            <div>
              <ArrowUturnLeftIcon className="h-6 w-6" onClick={() => navigate('/dashboard')} />
            </div>
            <h2 className="text-xl">{t('appbar.profile')}</h2>
            <div></div>
          </div>

          <div className="bg-white p-4 rounded-md">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => navigate('/profile/phone')}
            >
              <div className="flex items-center">
                <PhoneIcon className="h-5 w-5 mr-2" />
                <span className="text-gray-800">{t('phone.phone')}</span>
              </div>
              <div className="flex items-center text-gray-500">
                {currentUser?.user ? currentUser?.user?.phone : 'no phone'}
              </div>
            </div>
            <div className="my-2 border-b"></div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <CakeIcon className="h-5 w-5 mr-2" />
                <span className="text-gray-800">{t('completeprofile.dob')}</span>
              </div>
              <div className="flex items-center text-gray-500">
                {currentUser?.user ? formatedDate : 'no date'}
                <ChevronRightIcon className="h-5 w-5 ml-2" />
              </div>
            </div>
            <div className="my-2 border-b"></div>
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <UserIcon className="h-5 w-5 mr-2" />
                <span className="text-gray-800">{t('completeprofile.gender')}</span>
              </div>
              <div className="flex items-center text-gray-500 capitalize">
                {currentUser?.user?.gender && currentUser?.user?.gender}
                <ChevronRightIcon className="h-5 w-5 ml-2" />
              </div>
            </div>
          </div>

          <div className="bg-white p-4 rounded-md mt-4">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => navigate('/terms')}
            >
              <div className="flex items-center">
                <DocumentTextIcon className="h-5 w-5 mr-2" />
                <span className="text-gray-800">{t('profile.terms')}</span>
              </div>
              <ChevronRightIcon className="h-5 w-5" />
            </div>
            <div className="my-2 border-b"></div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => navigate('/policy')}
            >
              <div className="flex items-center">
                <DocumentTextIcon className="h-5 w-5 mr-2" />
                <span className="text-gray-800">{t('profile.policy')}</span>
              </div>
              <ChevronRightIcon className="h-5 w-5" />
            </div>
          </div>

          <div className="bg-white p-4 rounded-md mt-4">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <SpeakerWaveIcon className="h-5 w-5 mr-2" />
                <span className="text-gray-800">{t('profile.communication')}</span>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={currentUser?.user.allowCommunication ?? false}
                  onChange={() => {
                    if (currentUser?.user.allowCommunication) {
                      setDialogOpen(true);
                    } else {
                      handleUpdateCommunication(true);
                    }
                  }}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
              </div>
            </div>
          </div>

          <div className="text-center mt-4">
            <span className="text-white">{t('profile.support')}</span>
          </div>

          <div className="mt-4 px-8">
            <Button className="w-full" color="red" onClick={logout}>
              {t('profile.logout')}
              <ArrowRightEndOnRectangleIcon />
            </Button>
          </div>
        </div>
      </div>

      {dialogOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-lg font-semibold mb-4">Are you sure?</h2>
            <p className="text-gray-700 mb-6">
              EasyJacket won’t be able to send you any communication, even about forgotten items.
              Are you sure you want to opt out?
            </p>
            <div className="flex justify-end gap-2">
              <button className="bg-red-500 text-white py-2 px-4 rounded-md" onClick={handleOptOut}>
                Opt-out
              </button>
              <button className="bg-blue-500 text-white py-2 px-4 rounded-md" onClick={handleClose}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
