import { CreditCardIcon, ExclamationCircleIcon, TicketIcon } from '@heroicons/react/24/outline';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { api } from '../api';
import { classNames } from '../helpers';
import { useDebounce } from '../hooks/use-debounce';
import { TICKET_PURCHASE_LIMIT } from '../utils/constants';
import { plausible, trackEvent } from '../utils/track-events';
import { DashboardContext } from '../views';
import { Dialog } from './dialog';
import { Spinner } from './spinner';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const PaymentDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();

  const [numberOfTickets, setNumberOfTickets] = useState(1);
  const [tempNumberOfTickets, setTempNumberOfTickets] = useState(1);

  const [cardEnabled, setCardEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const ticketMissmatch = tempNumberOfTickets !== numberOfTickets;

  const {
    paymentCode,
    setPaymentCode,
    paymentInitiated,
    setPaymentInitiated,
    checkoutSession,
    setCheckoutSession,
    setMessage,
    handlePaymentCode,
    setPaymentCodeError,
    paymentCodeError,
  } = useContext(DashboardContext);

  const navigate = useNavigate();

  const handleSwish = async () => {
    setLoading(true);
    try {
      const { data: facility } = await api.facilitiesControllerGetFacilityByName({
        facilityName: paymentCode as string,
      });

      const { data } = await api.paymentsControllerInitSwishForFacility({
        facilityId: facility.id,
        initPaymentDto: { numberOfTickets },
      });

      clearPaymentCode();

      const appUrl = `swish://paymentrequest?token=${data.swishToken}&callbackurl=${window.location.origin}/swish/status?statusCheckToken=${data.statusCheckToken}`;
      window.location.href = appUrl;
    } catch (error) {
      setMessage('Något gick fel');
      setLoading(false);
    }
  };

  useEffect(() => {
    const paymentCodeLocalStorage = localStorage.getItem('paymentCode');
    if (paymentCodeLocalStorage) {
      setPaymentCode(paymentCodeLocalStorage);
      handlePaymentCode(paymentCodeLocalStorage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearPaymentCode = () => {
    const paymentCodeLocalStorage = localStorage.getItem('paymentCode');
    if (paymentCodeLocalStorage) {
      localStorage.removeItem('paymentCode');
    }
    setPaymentCode('');
  };

  const handleClose = () => {
    clearPaymentCode();
    onClose();
    setMessage('');
    setLoading(false);
    setPaymentInitiated(false);
    setCheckoutSession(undefined);
    setCardEnabled(false);
    setNumberOfTickets(1);
    setTempNumberOfTickets(1);
    navigate('/dashboard/paid');
  };

  useEffect(() => {
    if (open && !paymentCode) {
      const prefillPaymentCode = localStorage.getItem('prefillPaymentCode');
      if (prefillPaymentCode) {
        setPaymentCode(prefillPaymentCode);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    changeNumberOfTickets(Number(event.target.value));
  };

  const handleButtonPress = (i: number) => {
    changeNumberOfTickets(i);
  };

  const handleTempNumberOfTickets = (i: number) => {
    let val = i;

    if (val > TICKET_PURCHASE_LIMIT) {
      val = TICKET_PURCHASE_LIMIT;
    }
    setTempNumberOfTickets((prev) => prev + val);
  };

  const debouncedHandleInputChange = useDebounce(handleInputChange, 1000);
  const debouncedHandleButtonPress = useDebounce(handleButtonPress, 1000);

  const changeNumberOfTickets = (i: number) => {
    let numberOftickets = i;
    if (numberOftickets <= 0) {
      setNumberOfTickets(1);
      setTempNumberOfTickets(1);
      return;
    }
    if (i > TICKET_PURCHASE_LIMIT) {
      numberOftickets = TICKET_PURCHASE_LIMIT;
    }
    setNumberOfTickets(numberOftickets);
    setTempNumberOfTickets(numberOftickets);
    setLoading(true);
    handlePaymentCode(paymentCode, numberOftickets).finally(() => setLoading(false));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="rounded-lg flex flex-col gap-5 min-w-[224px]">
        {!checkoutSession && paymentInitiated ? (
          <div className="m-auto p-4">
            <Spinner />
          </div>
        ) : checkoutSession ? (
          <>
            {!cardEnabled && (
              <div>
                <div className="p-4 text-center">
                  <h5 className="text-xl font-medium text-black">
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    {checkoutSession.checkoutSession.metadata.facilityName} <br />
                    {checkoutSession.checkoutSession.amount_total / 100}{' '}
                    {checkoutSession.checkoutSession.currency}
                  </h5>
                  <p className="text-black">Antal biljetter {numberOfTickets}</p>
                </div>
                <div className="flex w-full flex-1 p-4 justify-center items-center">
                  <button
                    onClick={() => {
                      debouncedHandleButtonPress(tempNumberOfTickets - 1);
                      handleTempNumberOfTickets(-1);
                    }}
                    className={classNames(
                      'h-10 w-10 border border-primary-main text-primary-main bg-gray-600 grid place-content-center text-2xl',
                      tempNumberOfTickets <= 1 ? 'pointer-events-none opacity-30' : '',
                    )}
                  >
                    &#45;
                  </button>
                  <div className="flex justify-center items-center w-full flex-1 min-w-[96px]">
                    <input
                      type="number"
                      min={0}
                      max={TICKET_PURCHASE_LIMIT}
                      className="text-center text-2xl w-16 border-0 bg-transparent focus:ring-0"
                      disabled={loading}
                      value={tempNumberOfTickets}
                      onChange={(e) => {
                        debouncedHandleInputChange(e as ChangeEvent<HTMLInputElement>);
                        setTempNumberOfTickets(Number(e.target.value));
                      }}
                    />
                  </div>
                  <button
                    onClick={() => {
                      debouncedHandleButtonPress(tempNumberOfTickets + 1);
                      handleTempNumberOfTickets(+1);
                    }}
                    className={classNames(
                      'h-10 w-10 border border-primary-main text-primary-main bg-gray-600 grid place-content-center text-2xl',
                      tempNumberOfTickets >= TICKET_PURCHASE_LIMIT
                        ? 'pointer-events-none opacity-30'
                        : '',
                    )}
                  >
                    &#43;
                  </button>
                </div>
              </div>
            )}
            <div className="relative">
              {(ticketMissmatch || loading) && (
                <div className="absolute inset-0 z-1 grid place-content-center">
                  <Spinner />
                </div>
              )}
              <div
                className={classNames('flex flex-col gap-5', ticketMissmatch ? 'opacity-50' : '')}
              >
                <div className="p-4">
                  <button
                    className={classNames(
                      'w-full p-2 bg-black text-white text-sm font-medium rounded-md ',
                      loading || ticketMissmatch ? 'opacity-50' : '',
                    )}
                    disabled={loading || ticketMissmatch}
                    onClick={handleSwish}
                  >
                    <img className="w-6 inline-block mr-2" src="/swish.png" alt="Swish" />
                    Betala med Swish
                  </button>
                  <button
                    className="w-full mt-2 px-4 bg-white border-2 border-black text-black text-lg rounded-md flex justify-center gap-2 items-center"
                    disabled={loading || ticketMissmatch}
                    onClick={() => {
                      window.location.href = checkoutSession.checkoutSession.url;
                    }}
                  >
                    <CreditCardIcon className="h-6 mr-2" aria-hidden="true" />
                    <img src="/apple-pay-mark.svg" className="h-5" alt="Apple Pay" />
                    <img src="/google-pay-mark.svg" className="h-10" alt="Google Pay" />
                    <img src="/klarna-badge.png" className="h-9 -ml-3" alt="Klarna" />
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="p-8">
            <h5 className="text-xl font-medium text-black mb-5">
              {t('dashboard.enter_payment_code')}
            </h5>
            <form
              className="flex flex-col gap-5"
              onSubmit={(e) => {
                e.preventDefault();
                plausible.trackPageview({ url: `${window.location.origin}/${paymentCode}` });
                trackEvent('manual-payment-code-entered');
                handlePaymentCode(paymentCode);
              }}
            >
              <div>
                <label htmlFor="payment-code" className="block text-sm font-medium text-gray-900">
                  Payment code
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    autoFocus
                    onChange={(e) => {
                      if (paymentCodeError && setPaymentCodeError) setPaymentCodeError(false);
                      setPaymentCode(e.target.value);
                    }}
                    id="payment-code"
                    name="payment-code"
                    type="text"
                    value={paymentCode}
                    placeholder="club_kaskad..."
                    className={classNames(
                      'px-2 block w-full rounded-md py-1.5 pr-10 focus:ring-2 focus:ring-inset sm:text-sm',
                      paymentCodeError
                        ? 'text-red-900 ring-1 ring-red-300 focus:ring-red-500 placeholder:text-red-300'
                        : 'text-gray-900 ring-1 ring-gray-300 focus:ring-gray-500 placeholder:text-gray-300',
                    )}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                    {paymentCodeError ? (
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                    ) : (
                      <TicketIcon className="h-5 w-5 text-gray-400" />
                    )}
                  </div>
                </div>
                {paymentCodeError && <p className="mt-2 text-sm text-red-600">Not a valid code.</p>}
              </div>
              <button
                type="submit"
                className="w-full py-2 px-2 bg-black text-white text-sm font-medium rounded-md flex items-center justify-between hover:bg-gray-600 focus:outline focus:ring-2 focus:ring-gray-600"
              >
                {t('dashboard.continue')}
                <CreditCardIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </form>
          </div>
        )}
      </div>
    </Dialog>
  );
};
