/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useContext, useEffect, useState } from 'react';
import { useTickets } from '../api/queries';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { LoadingView } from '.';
import { api } from '../api';
import { useReactQuerySubscription } from '../api/sockets';
import { useProtectedContext } from '../components';
// import { CompleteUserProfile } from '../components/complete-user-profile';
import { DashboardLayout } from '../components/dashboard-layout';
import { PaymentDialog } from '../components/payment-dialog';
import { useAuth } from '../contexts/auth-context';
import { CheckedInQrCode, CheckoutSession, PaidQRCode, QRCode } from '../generated';
import { trackEvent } from '../utils/track-events';
import { CheckedInTicket } from './checked-in-ticket';
import { CheckedOutTicket } from './checked-out-ticket';
import { PaidTicket } from './paid-ticket';

export const DashboardContext = createContext<{
  paymentCode: string;
  setPaymentCode: (paymentCode: string) => void;
  paymentInitiated: boolean;
  setPaymentInitiated: (paymentInitiated: boolean) => void;
  checkoutSession: CheckoutSessionWithIsTest | undefined;
  setCheckoutSession: (checkoutSession: CheckoutSessionWithIsTest | undefined) => void;
  message: string;
  setMessage: (message: string) => void;
  handlePaymentCode: (paymentCode: string, numberOfTickets?: number) => Promise<void>;
  setPaymentModalOpen?: (open: boolean) => void;
  paymentModalOpen: boolean;
  paymentCodeError?: boolean;
  setPaymentCodeError?: (error: boolean) => void;
}>({
  paymentCode: '',
  // @ts-ignore
  setPaymentCode: (paymentCode: string) => {},
  paymentInitiated: false,
  // @ts-ignore
  setPaymentInitiated: (paymentInitiated: boolean) => {},
  checkoutSession: undefined as CheckoutSessionWithIsTest | undefined,
  // @ts-ignore
  setCheckoutSession: (checkoutSession: CheckoutSessionWithIsTest | undefined) => {},
  message: '',
  // @ts-ignore
  setMessage: (message: string) => {},
  // @ts-ignore
  handlePaymentCode: (paymentCode: string) => {},
  paymentModalOpen: false,

  // @ts-ignore
  setPaymentModalOpen: (open: boolean) => {},
  paymentCodeError: false,
});

export type CheckoutSessionWithIsTest = { checkoutSession: CheckoutSession; isTest: boolean };

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

export const DashboardView = () => {
  const { pathname } = useLocation();
  const isPaidView = pathname.includes('paid');
  const isCheckedInView = pathname.includes('checked-in');

  const { user } = useAuth();
  const { paid, checkedIn, personal } = useTickets(user?.user?.id);
  const { t } = useTranslation();

  const [paymentCode, setPaymentCode] = useState('');
  const [paymentInitiated, setPaymentInitiated] = useState(false);
  const [checkoutSession, setCheckoutSession] = useState<CheckoutSessionWithIsTest>();
  const [message, setMessage] = useState('');
  const [paymentCodeError, setPaymentCodeError] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  const handlePaymentCode = async (paymentCode: string, numberOfTickets = 1) => {
    setPaymentInitiated(true);
    setMessage('');

    try {
      const { data: facility } = await api.facilitiesControllerGetFacilityByName({
        facilityName: paymentCode as string,
      });

      const { data: checkoutSession } = await api.paymentsControllerInitPaymentForFacility({
        facilityId: facility.id,
        initPaymentDto: {
          numberOfTickets,
        },
      });

      setPaymentModalOpen(true);

      trackEvent('payment-initiated');

      localStorage.setItem('prefillPaymentCode', paymentCode);

      setCheckoutSession({
        checkoutSession,
        isTest: facility.isTestFacility,
      });
    } catch (error) {
      console.error(error);
      setMessage(t('Felaktig kod'));
      setPaymentInitiated(false);
      setPaymentCodeError(true);
    }
  };

  const { closePersonalTicket } = useProtectedContext();

  const handlePaymentEvent = (data: string) => {
    setPaymentCode(data);
    setPaymentModalOpen(true);
    handlePaymentCode(data);
    closePersonalTicket();
  };
  const slideTickets: (PaidQRCode | CheckedInQrCode | QRCode)[] =
    isPaidView && paid
      ? paid
      : isCheckedInView && checkedIn
        ? checkedIn
        : personal
          ? [personal]
          : [];

  useEffect(() => {
    const clampSlideIndex = () => {
      if (!slideTickets) return;

      const newSlideIndex = clamp(slideIndex, 0, slideTickets.length - 1);

      if (newSlideIndex !== slideIndex) {
        setSlideIndex(newSlideIndex);
      }

      return;
    };
    clampSlideIndex();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideTickets]);

  useReactQuerySubscription(user?.user?.id, handlePaymentEvent);

  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderQrCode = () => {
    if (!checkedIn || !paid) return <LoadingView />;

    return (
      <DashboardLayout>
        <div className="w-full items-center flex">
          <SwipeableViews
            disabled={slideTickets && slideTickets.length <= 1}
            onChangeIndex={(index) => setSlideIndex(index)}
            index={clamp(slideIndex, 0, slideTickets.length - 1)}
            enableMouseEvents
            style={{
              padding: '0 10%',
            }}
          >
            {slideTickets
              ?.map((qrCode, index) => {
                const { state } = qrCode;
                const key =
                  qrCode.userId +
                  qrCode.state +
                  index +
                  (qrCode as PaidQRCode).paymentTicketId +
                  (qrCode as CheckedInQrCode).slotId;

                if (state === 'CHECKED_IN') {
                  return (
                    <div key={key} style={{ height: '100%', padding: 10 }}>
                      <CheckedInTicket qrCode={qrCode} />
                    </div>
                  );
                }

                if (state === 'PAID') {
                  return (
                    <div key={key} style={{ height: '100%', padding: 10 }}>
                      <PaidTicket qrCode={qrCode} />
                    </div>
                  );
                }

                if (state === 'CHECKED_OUT') {
                  return (
                    <div key={key} style={{ height: '100%', padding: 10 }}>
                      <CheckedOutTicket qrCode={qrCode} />
                    </div>
                  );
                }

                return null;
              })
              .filter(Boolean)}
          </SwipeableViews>
        </div>
      </DashboardLayout>
    );
  };

  // const hasCheckedIn = checkedIn && checkedIn?.length > 0;

  // if (
  //   (!user?.profileComplete &&
  //     user?.user?.createdAt &&
  //     new Date(user?.user?.createdAt).getTime() + 10 * 60 * 1000 < new Date().getTime()) ||
  //   (!user?.profileComplete && hasCheckedIn)
  // ) {
  //   return <CompleteUserProfile />;
  // }

  return (
    <DashboardContext.Provider
      value={{
        paymentCode,
        setPaymentCode,
        paymentInitiated,
        setPaymentInitiated,
        checkoutSession,
        setCheckoutSession,
        message,
        setMessage,
        handlePaymentCode,
        setPaymentModalOpen,
        paymentModalOpen,
        paymentCodeError,
        setPaymentCodeError,
      }}
    >
      {renderQrCode()}
      <PaymentDialog open={paymentModalOpen} onClose={() => setPaymentModalOpen(false)} />
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error('useDashboardContext must be used within a DashboardContextProvider');
  }

  return context;
};
