import { PhoneNumberForm } from '../components/forms/phone-number-form';
import { VerificationCodeForm } from '../components/forms/verification-code-form';
import { NewLogo } from '../components/logo';
import { Spinner } from '../components/spinner';
import { Dialog } from '../components/ui/dialog';
import { usePhoneAuth } from '../hooks/use-phone-auth';

export const PhoneLoginView = () => {
  const { handleInit, handleSignIn, showCodeInput, handleRetry, isLoading } = usePhoneAuth();

  return (
    <>
      <div className="w-full h-full flex justify-evenly flex-col ">
        <NewLogo />
        <div className="px-8">
          {!showCodeInput && <PhoneNumberForm onSubmit={handleInit} />}
          {showCodeInput && <VerificationCodeForm onSubmit={handleSignIn} onRetry={handleRetry} />}
        </div>
        <div />
      </div>
      <Dialog open={isLoading} onClose={() => {}} className="relative z-10">
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Spinner />
          </div>
        </div>
      </Dialog>
    </>
  );
};
